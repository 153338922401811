import type {ReactElement} from 'react';
import {cva} from 'class-variance-authority';

import Icon from '@/components/base/elements/Icon/Icon';
import Typography from '@/components/base/elements/Typography/Typography';
import Badge from '@/components/base/elements/Badge/Badge';
import {twMerge} from '@/stylesheets/twMerge';
import type {IconProps} from '@/components/base/elements/Icon/Icon';
import type {Mode, KickerAs, HeadingAs} from '@/components/base/types';

import Kicker from './Kicker';

export const SIZES: string[] = [
  'dsp',
  't1',
  't2',
  't3',
  't4',
  't5',
  't6',
  't7',
  't8',
];

const wrapperStyles = cva('', {
  variants: {
    center: {
      true: 'text-center',
      false: 'text-left',
    },
    mode: {
      light: 'text-section-light-text',
      dark: 'text-section-dark-text',
    },
  },
  defaultVariants: {
    mode: 'light',
    center: false,
  },
});

const headingWrapperStyles = cva('flex', {
  variants: {
    center: {
      true: 'justify-center',
      false: '',
    },
  },
});

const eyebrowStyles = cva('pb-xs', {
  variants: {
    mode: {
      light: 'text-eyebrow-light-text',
      dark: 'text-eyebrow-dark-text',
    },
  },
});

const subheadStyles: {[key: (typeof SIZES)[number]]: string} = {
  dsp: 'pt-md',
  t1: 'pt-md',
  t2: 'pt-sm',
  t3: 'pt-sm',
  t4: 'pt-sm',
  t5: 'pt-sm',
  t6: 'pt-sm',
  t7: 'pt-2',
  t8: 'pt-2',
};
export interface HeadingGroupContentProps {
  kicker?: string;
  headingHtml?: ReactElement | string;
  subheadHtml?: ReactElement | string;
}

export interface HeadingGroupProps
  extends HeadingGroupContentProps,
    Omit<React.HTMLAttributes<HTMLDivElement>, 'children'> {
  center?: boolean;
  className?: string;
  kickerAs?: KickerAs;
  headingAs?: HeadingAs;
  mode?: Mode;
  size?: (typeof SIZES)[number];
  iconProps?: IconProps;
  isKickerBadge?: boolean;
}

export default function HeadingGroup({
  center,
  className,
  kicker,
  kickerAs = 'p',
  headingAs = 'h2',
  headingHtml,
  iconProps,
  isKickerBadge = false,
  mode = 'light',
  size = 't2',
  subheadHtml,
  ...props
}: HeadingGroupProps) {
  const hasParagraphTag =
    typeof subheadHtml === 'string' && subheadHtml?.includes('<p>');

  const sizeMap: {
    [key: string]: {[key: (typeof SIZES)[number]]: 'lg' | 'base' | 'sm'};
  } = {
    kicker: {
      dsp: 'lg',
      t1: 'lg',
      t2: 'lg',
      t3: 'lg',
      t4: 'base',
      t5: 'base',
      t6: 'base',
      t7: 'sm',
      t8: 'sm',
    },
    subhead: {
      dsp: 'lg',
      t1: 'lg',
      t2: 'lg',
      t3: 'base',
      t4: 'base',
      t5: 'base',
      t6: 'base',
      t7: 'sm',
      t8: 'sm',
    },
  };
  const componentName = 'heading-group';

  return (
    <div
      className={twMerge(wrapperStyles({center, mode}), className)}
      data-mode={mode}
      data-component-name={componentName}
      {...props}
    >
      {kicker &&
        (isKickerBadge ? (
          <Badge mode={mode} as={kickerAs} className="mb-xs">
            {kicker}
          </Badge>
        ) : (
          <Kicker
            as={kickerAs}
            size={sizeMap.kicker[size]}
            className={eyebrowStyles({mode})}
          >
            {kicker}
          </Kicker>
        ))}
      {headingHtml && (
        <>
          {iconProps ? (
            <div className={headingWrapperStyles({center})}>
              <Typography size={size} as={headingAs}>
                {headingHtml}
              </Typography>
              <Icon className="mx-2 self-start" size={18} {...iconProps} />
            </div>
          ) : (
            <Typography size={size} as={headingAs}>
              {headingHtml}
            </Typography>
          )}
        </>
      )}
      {subheadHtml && (
        <Typography
          className={(kicker || headingHtml) && subheadStyles[size]}
          as={hasParagraphTag ? 'div' : 'p'}
          size={`body-${sizeMap.subhead[size]}`}
        >
          {subheadHtml}
        </Typography>
      )}
    </div>
  );
}
