import {cva} from 'class-variance-authority';

import {twMerge} from '@/stylesheets/twMerge';
import type {Mode, KickerAs} from '@/components/base/types';
import Typography from '@/components/base/elements/Typography/Typography';

interface BadgeProps {
  className?: string;
  children: string;
  mode?: Mode;
  as?: KickerAs;
}

const BadgeStyles = cva(
  'inline-block text-xs uppercase font-bold px-2 py-1 rounded align-top',
  {
    variants: {
      mode: {
        light: 'bg-badge-light text-white',
        dark: 'bg-badge-dark text-black',
      },
    },
  },
);

function Badge({className, children, mode = 'light', as = 'p'}: BadgeProps) {
  const componentName = 'badge';
  return (
    <Typography
      as={as}
      isRichtext={false}
      className={twMerge(BadgeStyles({mode}), className)}
      data-component-name={componentName}
    >
      {children}
    </Typography>
  );
}

export default Badge;
